
import { defineComponent, getCurrentInstance, onMounted, ref, watch } from 'vue'
import Card from 'primevue/card'
import apiUseCase from '@/usecase/apiUseCase'
import { useStore } from 'vuex'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'
import moment from 'moment'
import currencyNumberFormat from '@/utils/helpers/rupiahFormat'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ProgressSpinner from 'primevue/progressspinner'
import Paginator from '@/views/components/paginator/Paginator.vue'
import EmptyState from '@/views/components/empty/State.vue'
import InputIconRight from '@/views/components/form/InputIconRight.vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'VerbalDetail',
  components: {
    Card,
    DataTable,
    Column,
    ProgressSpinner,
    Paginator,
    EmptyState,
    InputIconRight
  },
  setup() {
    const app = getCurrentInstance()
    const store = useStore()
    const router = useRouter()
    const endpoint = 'transaction/v1/Dashboard'
    const dataSource = ref([]) as any
    const selectedConductor = ref(null)
    const inputSearch = ref('')
    const filters = {
      pageNumber: 1,
      pageSize: 5,
      totalRecords: 0,
      rowPerPageOptions: [5, 10]
    }
    const isLoading = ref(true)
    const {
      $icon, $toast, $numbers, $socketHub
    } = app!.appContext.config.globalProperties
    const icon = $icon

    const baseRoute = 'approve-deposits'

    const getAllInvalidConductor = async () => {
      await apiUseCase.get(`${endpoint}/CrewClosedTransactionDashboard${returnUrlPrams({
        pageNumber: filters.pageNumber,
        pageSize: filters.pageSize,
        filterField: ['q'],
        search: inputSearch.value,
      })}`).then((response) => {
        console.log('response', response)
        dataSource.value = response.result.filter((x: any) => x.TransactionPps.length > 1)
        filters.totalRecords = dataSource.value.length
        isLoading.value = false
      })
    }

    const onChangePage = ({ page, rows }: any) => {
      // console.log('page', page)
      filters.pageSize = rows
      filters.pageNumber = page + 1
      getAllInvalidConductor()
    }

    const selectInvalidCondectur = (id: any) => {
      if (selectedConductor.value === id) {
        selectedConductor.value = null
      } else {
        selectedConductor.value = id
      }
    }

    const onSearch = () => {
      filters.pageNumber = 1
      getAllInvalidConductor()
    }

    const toApprovePp = (id: any) => {
      console.log('id', id)
      router.push({
        // path: baseRoute.value
        name: `${baseRoute}-detail`,
        params: {
          id
        }
      })
    }

    watch(() => store.getters.getReloadDashboardRit1Table, (val) => {
      if (val) {
        // isLoading.value = true
        getAllInvalidConductor()
      }
    })

    onMounted(() => {
      getAllInvalidConductor()
    })
    return {
      isLoading,
      dataSource,
      currencyNumberFormat,
      moment,
      selectedConductor,
      onChangePage,
      filters,
      selectInvalidCondectur,
      inputSearch,
      onSearch,
      icon,
      toApprovePp
    }
  }
})
